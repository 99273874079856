@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-background {
  background: linear-gradient(
    to right,
    #ff5733,
    #33ff57
  ); /* Replace with your primary and secondary color codes */
}

body {
  /* Add the background color you want for the entire app */
  background-color: ; /* For example, light gray */
}

/* styles.css (or your custom CSS file) */

/* Define the custom class for the line */
.line-before::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -5px; /* Adjust this value to move the line horizontally */
  width: 10px; /* Adjust this value to change the width of the line */
  height: 1px; /* Adjust this value to change the height of the line */
  background-color: #000; /* Adjust this value to change the line color */
  border-radius: 9999px; /* This value will create rounded edges */
}
